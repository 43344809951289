var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isView)?_c('form-panel',{ref:"formPanel",staticClass:"quTypeContent",attrs:{"form":_vm.item,"hasHeader":false,"footerShow":false}},[_c('el-form-item',{attrs:{"label":'投票问题' + (_vm.index + 1),"rules":[{ required: true, message: '请输入投票题目', trigger: 'blur' }],"prop":"title"}},[_c('v-input',{attrs:{"placeholder":"请输入投票问题题目","maxlength":150,"width":450},model:{value:(_vm.item.title),callback:function ($$v) {_vm.$set(_vm.item, "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.title"}})],1),(_vm.item.quType === 1 || _vm.item.quType === 2 || _vm.item.quType === 11)?[_vm._l((_vm.item.optionList),function(option,idx){return _c('el-form-item',{key:'option' + idx,attrs:{"label":idx === 0 ? '问题选项' : '',"required":""}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-top"},[_c('v-input',{attrs:{"placeholder":"例如：A、B或1、2","maxlength":20,"value":_vm.setAndReturnKey(option, idx),"width":42,"disabled":true}}),_c('el-form-item',{staticStyle:{"display":"inline-block"},attrs:{"rules":[
                {
                  required: true,
                  validator: _vm.optionValid,
                  trigger: 'blur',
                },
              ],"label-width":"100px","label":"选项内容","prop":`optionList.${idx}.optionTitle`}},[_c('v-input',{attrs:{"placeholder":"请输入选项内容","maxlength":150,"width":272},model:{value:(option.optionTitle),callback:function ($$v) {_vm.$set(option, "optionTitle", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"option.optionTitle"}})],1)],1),(_vm.item.quType === 11)?_c('div',{staticClass:"content-bottom"},[_c('el-form-item',{staticStyle:{"display":"inline-block"},attrs:{"rules":[
                {
                  required: true,
                  // validator: optionValid,
                  message: '图片不可为空',
                  trigger: 'change',
                },
              ],"label-width":"100px","label":"选项图片","prop":`optionList.${idx}.imageUrls`}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":option.imageUrls},on:{"update:imgUrls":function($event){return _vm.$set(option, "imageUrls", $event)},"update:img-urls":function($event){return _vm.$set(option, "imageUrls", $event)}}})],1)],1):_vm._e()]),_c('v-button',{directives:[{name:"show",rawName:"v-show",value:(
            /*
              类型为1、2且选项个数大于2 或者 类型为5且选项大于1
              
              且isShow 为 true
            */
            ((_vm.item.optionList.length > 2 &&
              (_vm.item.quType === 1 || _vm.item.quType === 2)) ||
              (_vm.item.optionList.length > 1 && _vm.item.quType === 5)) &&
            _vm.isShow
          ),expression:"\n            /*\n              类型为1、2且选项个数大于2 或者 类型为5且选项大于1\n              \n              且isShow 为 true\n            */\n            ((item.optionList.length > 2 &&\n              (item.quType === 1 || item.quType === 2)) ||\n              (item.optionList.length > 1 && item.quType === 5)) &&\n            isShow\n          "}],staticClass:"delOpt",attrs:{"type":"default","icon":"el-icon-close","circle":""},on:{"click":function($event){return _vm.delOps(idx)}}})],1)}),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow && _vm.item.quType !== 11),expression:"isShow && item.quType !== 11"}]},[_c('v-button',{attrs:{"text":"+ 新增选项","width":250,"size":"small","plain":""},on:{"click":function($event){return _vm.addOps(_vm.item.quType)}}})],1)]:_vm._e(),_c('el-form-item',{attrs:{"label":_vm.item.quType === 2 ? '选项模式' : ''}},[_c('div',{staticClass:"multi-modal"},[(_vm.item.quType === 2)?_c('div',{staticStyle:{"display":"flex"}},[_vm._v(" 至少选 "),_c('el-form-item',{staticStyle:{"display":"inline-block"},attrs:{"rules":[
              {
                required: true,
                message: '请输入最小选项个数',
                trigger: ['blur', 'change'],
              },
            ],"prop":"minSelection"}},[_c('v-input-number',{staticClass:"optPadding",attrs:{"width":100,"controls":true,"controls-position":"right","size":"small","min":_vm.minSelection,"max":_vm.item.maxSelection || 1,"disabled":!_vm.isShow},model:{value:(_vm.item.minSelection),callback:function ($$v) {_vm.$set(_vm.item, "minSelection", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.minSelection"}})],1),_vm._v(" 项，至多选 "),_c('el-form-item',{staticStyle:{"display":"inline-block"},attrs:{"rules":[
              {
                required: true,
                message: '请输入最大选项个数',
                trigger: 'blur',
              },
            ],"prop":"maxSelection"}},[_c('v-input-number',{staticClass:"optPadding",attrs:{"width":100,"controls":true,"controls-position":"right","size":"small","min":_vm.item.minSelection || _vm.minSelection,"max":_vm.maxSelection,"disabled":!_vm.isShow},model:{value:(_vm.item.maxSelection),callback:function ($$v) {_vm.$set(_vm.item, "maxSelection", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.maxSelection"}})],1),_vm._v(" 项 ")],1):_vm._e(),_c('v-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],class:_vm.item.quType === 2 ? 'delQue-multi' : 'delQue-single',attrs:{"type":"danger","round":"","plain":"","icon":"el-icon-delete","text":"删除该问题"},on:{"click":_vm.delQuestion}})],1)])],2):_c('div',{staticClass:"isView"},[(_vm.item.quType === 1 || _vm.item.quType === 2 || _vm.item.quType == 11)?[_c('div',{staticClass:"questionTitle"},[_vm._v(" "+_vm._s(`${_vm.index + 1}、${_vm.item.title}${_vm.item.quType === 2 ? "【多选】" : ""}`)+" ")]),_c('ul',{staticClass:"optionList"},_vm._l((_vm.item.optionList),function(option,i){return _c('li',{key:i},[_c('div',{staticClass:"option"},[_vm._v(" "+_vm._s(`${option.optionKey}、${option.optionTitle}`)+" ")]),_c('div',{staticClass:"value"},[_c('span',[_vm._v("选择人数：")]),_c('span',{staticClass:"count"},[_vm._v(_vm._s(option.checkedCount))]),_c('span',[_vm._v(" | 占比：")]),_c('span',{staticClass:"scale"},[_vm._v(_vm._s(_vm.getScale(option)))])])])}),0)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }