<template>
  <form-panel
    ref="formPanel"
    :form="item"
    :hasHeader="false"
    :footerShow="false"
    class="quTypeContent"
    v-if="!isView"
  >
    <el-form-item
      :label="'投票问题' + (index + 1)"
      :rules="[{ required: true, message: '请输入投票题目', trigger: 'blur' }]"
      prop="title"
    >
      <v-input
        placeholder="请输入投票问题题目"
        :maxlength="150"
        v-model.trim="item.title"
        :width="450"
      ></v-input>
    </el-form-item>

    <template
      v-if="item.quType === 1 || item.quType === 2 || item.quType === 11"
    >
      <el-form-item
        v-for="(option, idx) in item.optionList"
        :key="'option' + idx"
        :label="idx === 0 ? '问题选项' : ''"
        required
      >
        <div class="content">
          <div class="content-top">
            <v-input
              placeholder="例如：A、B或1、2"
              :maxlength="20"
              :value="setAndReturnKey(option, idx)"
              :width="42"
              :disabled="true"
            ></v-input>
            <el-form-item
              style="display: inline-block"
              :rules="[
                {
                  required: true,
                  validator: optionValid,
                  trigger: 'blur',
                },
              ]"
              label-width="100px"
              label="选项内容"
              :prop="`optionList.${idx}.optionTitle`"
            >
              <!-- <span class="optPadding">选项内容：</span> -->
              <v-input
                placeholder="请输入选项内容"
                :maxlength="150"
                v-model.trim="option.optionTitle"
                :width="272"
              ></v-input>
            </el-form-item>
          </div>
          <div class="content-bottom" v-if="item.quType === 11">
            <el-form-item
              style="display: inline-block"
              :rules="[
                {
                  required: true,
                  // validator: optionValid,
                  message: '图片不可为空',
                  trigger: 'change',
                },
              ]"
              label-width="100px"
              label="选项图片"
              :prop="`optionList.${idx}.imageUrls`"
            >
              <!-- <span class="optPadding">选项图片：</span> -->
              <v-upload :limit="1" :imgUrls.sync="option.imageUrls" />
            </el-form-item>
          </div>
        </div>

        <v-button
          type="default"
          icon="el-icon-close"
          class="delOpt"
          circle
          @click="delOps(idx)"
          v-show="
            /*
              类型为1、2且选项个数大于2 或者 类型为5且选项大于1
              
              且isShow 为 true
            */
            ((item.optionList.length > 2 &&
              (item.quType === 1 || item.quType === 2)) ||
              (item.optionList.length > 1 && item.quType === 5)) &&
            isShow
          "
        ></v-button>
      </el-form-item>
      <el-form-item v-show="isShow && item.quType !== 11">
        <v-button
          text="+ 新增选项"
          @click="addOps(item.quType)"
          :width="250"
          size="small"
          plain
        ></v-button>
      </el-form-item>
    </template>

    <el-form-item :label="item.quType === 2 ? '选项模式' : ''">
      <div class="multi-modal">
        <div style="display: flex" v-if="item.quType === 2">
          至少选
          <el-form-item
            style="display: inline-block"
            :rules="[
              {
                required: true,
                message: '请输入最小选项个数',
                trigger: ['blur', 'change'],
              },
            ]"
            prop="minSelection"
          >
            <v-input-number
              v-model.trim="item.minSelection"
              class="optPadding"
              :width="100"
              :controls="true"
              controls-position="right"
              size="small"
              :min="minSelection"
              :max="item.maxSelection || 1"
              :disabled="!isShow"
            />
          </el-form-item>
          项，至多选
          <el-form-item
            style="display: inline-block"
            :rules="[
              {
                required: true,
                message: '请输入最大选项个数',
                trigger: 'blur',
              },
            ]"
            prop="maxSelection"
          >
            <v-input-number
              v-model.trim="item.maxSelection"
              class="optPadding"
              :width="100"
              :controls="true"
              controls-position="right"
              size="small"
              :min="item.minSelection || minSelection"
              :max="maxSelection"
              :disabled="!isShow"
            />
          </el-form-item>
          项
        </div>
        <v-button
          v-show="isShow"
          type="danger"
          @click="delQuestion"
          round
          plain
          icon="el-icon-delete"
          :class="item.quType === 2 ? 'delQue-multi' : 'delQue-single'"
          text="删除该问题"
        ></v-button>
      </div>
    </el-form-item>
  </form-panel>

  <div v-else class="isView">
    <template
      v-if="item.quType === 1 || item.quType === 2 || item.quType == 11"
    >
      <div class="questionTitle">
        {{
          `${index + 1}、${item.title}${item.quType === 2 ? "【多选】" : ""}`
        }}
      </div>
      <ul class="optionList">
        <li v-for="(option, i) in item.optionList" :key="i">
          <div class="option">
            {{ `${option.optionKey}、${option.optionTitle}` }}
          </div>
          <div class="value">
            <span>选择人数：</span>
            <span class="count">{{ option.checkedCount }}</span>
            <span> | 占比：</span>
            <span class="scale">{{ getScale(option) }}</span>
            <!-- {{ `选择人数：${option.checkedCount} | 占比：${getScale(option)}` }} -->
          </div>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import { getSteamAnswerListURL } from "../api";
export default {
  name: "quTypeConent",
  props: {
    // 参与人总数
    answerCount: {
      type: [String, Number],
    },
    // 题目对象
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 题目对象集合
    listData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 索引
    index: {
      type: Number,
      default: 0,
    },
    // 是否可编辑
    isView: {
      type: Boolean,
    },
  },
  data() {
    return {
      minSelection: 1,
      scaleColorOps: [
        "#DCE6F7FF",
        "#CDDEFCFF",
        "#A4C3FAFF",
        "#367BF4B3",
        "#367BF4D1",
      ],
      /* v-dialog */
      isShowDialog: false,
      headers: [
        {
          prop: "userName",
          label: "用户姓名",
        },
        {
          prop: "mobileNum",
          label: "手机号",
        },
        {
          prop: "answer",
          label: "回答",
        },
      ],
      tableUrl: getSteamAnswerListURL,
      searchParam: {
        steamId: "",
        mobileNum: "",
        userName: "",
      },
      // 26个字母集合
      letters: [...Array(26).keys()].map((i) => String.fromCharCode(i + 65)),
    };
  },
  computed: {
    maxSelection() {
      return this.item.optionList.length;
    },
    // 判断是否为特殊样式--对抗赛
    // 通过是否已有参与人判断是否可删除问题或选项
    isShow() {
      let result = true;
      if (this.item.optionList) {
        this.item.optionList.forEach((opt) => {
          // 已有人参与 或者 编辑
          if (opt.checkedCount) {
            result = false;
          }
        });
      }
      // console.log(result, this.answerCount, "this.answerCount");
      return result && this.answerCount == 0;
    },
  },
  methods: {
    showDialog(item) {
      this.isShowDialog = true;
      this.searchParam.steamId = item.id;
      if (this.$refs.list) {
        this.$refs.list.search();
      }
    },
    addOps() {
      if (this.item.optionList.length >= 26) {
        this.$message.warning("选项个数不能超过26个！");
        return;
      }
      this.item.optionList.push({
        // optionTitle: "",
        // optionKey: "",
        checkedCount: 0, // 选中人数
        checkedPoints: 0, // 选中分值
        content: "", // 内容
        imageUrls: "", // 图片
        optionKey: "", // 选项key
        optionTitle: "", // 标题
      });
    },
    delOps(index) {
      this.item.optionList.splice(index, 1);
    },
    delQuestion() {
      this.$emit("delQuestion", this.item, this.index);
      // 是否保存 如果保存调接口删除，反之直接删除
      // if (this.item.isSave) {
      //   //
      // } else {
      // this.listData.splice(this.index, 1);
      // }
    },
    validate() {
      return this.$refs.formPanel.validate();
    },
    // saveQuestion(item) {
    //   this.$emit("saveQue", this.item);
    // },
    optionValid(rule, value, callback) {
      const fieldArr = rule.field.split(".");
      const optionTitle = this.item[fieldArr[0]][fieldArr[1]].optionTitle;
      const optionKey = this.item[fieldArr[0]][fieldArr[1]].optionKey;
      const isRepeat =
        this.item[fieldArr[0]]
          .map((v) => v.optionKey)
          .filter((key) => key === optionKey).length > 1;
      // console.log(
      //   this.item[fieldArr[0]].map((v) => v.optionKey),
      //   optionKey
      // );
      if (!optionTitle) {
        callback(new Error("请输入选项内容"));
      } else if (optionKey !== "0" && !optionKey) {
        callback(new Error("请输入选项值"));
      } else if (isRepeat) {
        callback(new Error("同一问题下选项值不可重复"));
      } else if (/,/.test(optionKey)) {
        callback(new Error("选项值不能存在‘,’"));
      } else {
        callback();
      }
    },
    getScale(opt, quType) {
      // const total = this.item.optionList.reduce(
      //   (acc, cur) => acc + Number(cur.checkedCount) || 0,
      //   0
      // );
      console.log(this.answerCount, "this.answerCount");
      const total = this.answerCount;
      const cur = quType === 5 ? opt : opt.checkedCount;
      return `${((cur / total || 0) * 100).toFixed(2)}%`;
    },
    setAndReturnKey(o, i) {
      this.$set(o, "optionKey", this.letters[i]);
      return o.optionKey;
    },
  },
};
</script>

<style lang="less" scoped>
.quTypeContent {
  .optPadding {
    padding: 0 10px;
    display: inline-block;
  }
  ::v-deep .v-button .el-button {
    min-width: 32px;
  }
  .multi-modal {
    display: flex;
  }
  .delOpt {
    margin-left: 10px;
  }
  .delQue-single {
    padding-left: 400px;
  }
  .delQue-multi {
    padding-left: 32px;
  }
  .saveQue {
    padding-left: 10px;
  }
}
.content {
  .content-top {
    display: flex;
    align-items: center;
  }
  .content-bottom {
    margin: 20px 0 0 45px;
  }
}
.isView {
  word-wrap: break-word;
  word-break: break-all;
  .questionTitle {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 22px;
    margin-top: 12px;
  }
  .optionList {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
    padding: 7px 40px 7px 16px;
    > li {
      display: flex;
      margin: 8px 0px 8px 0px;
      .option {
        width: 50%;
      }
      .value {
        margin-left: 24px;
        white-space: nowrap;
        .count {
          font-weight: bold;
        }
        .scale {
          font-weight: bold;
        }
      }
    }
    .inputText {
      cursor: pointer;
      color: #409eff;
    }
    .option-wrap {
      .option-text {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        // padding: 7px 40px 7px 16px;
        padding: 7px 0;
        word-wrap: break-word;
        word-break: break-all;
      }
      .option-block {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .block-color {
          box-sizing: border-box;
          width: 132px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #43474fff;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid #cbcbcb;
          .option-data {
            font-size: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            line-height: 20px;
            .label {
              word-wrap: break-word;
              word-break: break-all;
            }
          }
          &:not(:last-child) {
            margin-right: 6px;
          }
          &:last-child {
            color: #000000d9;
          }
        }
      }
    }
  }
}
.v-dialog {
  .chooseList {
    height: 500px;
  }
}
</style>
